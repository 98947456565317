import {useMutation} from "react-query";
import {BackgroundImage, BackgroundImageService, ImageBackofficeResponseDto} from "../services/BackgroundImageService"; // Assuming the DTO is in the same service file

// Mutation hook
export const useBackgroundImageMutation = () => {
    return useMutation<ImageBackofficeResponseDto, Error, BackgroundImage>(
        async (newImage: BackgroundImage) => {
            return await BackgroundImageService.Create(newImage);
        }
    );
};
