import React, {useEffect, useState} from 'react';
import {Button, Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {BackgroundImage} from '../../services/BackgroundImageService';
import {ImageUpload} from '../../components/ImageUpload';
import {useBackgroundImageMutation} from "../../queries/useBackgroundImageMutation";

const useStyles = makeStyles(() => ({
    errorContainer: {
        textAlign: 'center',
        fontSize: 12,
        marginBottom: 16,
        padding: 10,
        borderRadius: 5,
        color: 'white',
        fontWeight: 600,
        backgroundColor: '#c94352',
    }
}));

const ImagesEdit = () => {

    const {mutate, isLoading, error: networkError, isSuccess} = useBackgroundImageMutation();

    const [object, setObject] = useState<BackgroundImage>({} as BackgroundImage)
    const [error, setError] = useState<string>();

    const history = useHistory();
    const classes = useStyles();

    const onSubmit = () => {
        if (!object.name || !object.imageString) {
            setError("⚠️ Bitte füllen Sie die Pflichtfelder aus. ")
            return;
        }
        mutate(object);
    }

    useEffect(() => {
        if (networkError) {
            setError(networkError?.message)
        }
    }, [networkError]);

    useEffect(() => {
        if (isSuccess) {
            history.push("/images")
        }
    }, [history, isSuccess])

    return (
        <div className={"w-full flex justify-center"}>
            <div className={"max-w-4xl"}>
                <div className={"flex justify-between mb-4"}>
                    <Typography variant={"h5"}>Hintergründe</Typography>
                </div>

                <form style={{paddingTop: 16}} onSubmit={onSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name *"
                                        onChange={(e) => setObject({...object, name: e.target.value})}
                                        size="small"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2"
                                                style={{fontWeight: 600, marginBottom: 16}}>Grafik</Typography>
                                    <ImageUpload onImage={image => {
                                        setObject({...object, imageString: image})
                                    }}/>

                                    {object.imageString && (
                                        <div>
                                            <img alt={"Gerade hochgeladenes Bild"} style={{maxWidth: "100%"}}
                                                 src={`data:image/jpg;base64,${object.imageString}`}/>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            {error !== undefined && error.length > 0 && (
                                <div className={classes.errorContainer}>
                                    {error}
                                </div>)
                            }
                            <Button color="primary" onClick={() => onSubmit()} fullWidth variant="contained"
                                    disabled={isLoading}>
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default ImagesEdit;
