import axios, {AxiosPromise} from "axios";

export type BackgroundImage = {
    imageId: number;
    name: string;
    imageString: string;
    createdAt: string;
}

export interface ImageBackofficeResponseDto {
    imageId: number;        // integer($int32)
    name: string | null;    // nullable: true
    imageString: string | null; // nullable: true
    createdAt: string;      // string($date-time)
}

export class BackgroundImageService {
    static async GetAll(): Promise<Array<BackgroundImage>> {
        return axios.get(`/backoffice/images`)
            .then(async (res) => {
                return res.data;
            });
    }

    static async Create(item: BackgroundImage): Promise<ImageBackofficeResponseDto> {
        return axios.post(`/backoffice/images`, {
            name: item.name,
            imageString: item.imageString
        }).then((res) => {
            return res.data as ImageBackofficeResponseDto;
        }).catch((error) => {
            throw new Error(`Error creating image: ${error.response?.status || error.message}`);
        });
    }

    static async Delete(id: number): Promise<AxiosPromise> {
        return axios.delete(`/backoffice/images/${id}`)
    }
}
