import React, {useState} from "react";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import moment from "moment";
import {useBackgroundImagesQuery} from "../../queries/useBackgroundImagesQuery";
import {BackgroundImage, BackgroundImageService} from "../../services/BackgroundImageService";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function BackgroundImagesPage() {
    const {data: images, isLoading, refetch} = useBackgroundImagesQuery();

    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [toDelete, setToDelete] = useState<BackgroundImage>();
    
    const onCreate = () => {
        history.push("/images/create");
    };

    const onDelete = async () => {
        try {
            await BackgroundImageService.Delete(toDelete?.imageId ?? 0);
            await refetch();
        } catch (e: any) {
            console.error(e.message);
            alert(`Delete coupon ${toDelete?.name} with error: ${e.message}`);
        }
    };

    const openDialog = (
        event:
            | React.MouseEvent<HTMLAnchorElement>
            | React.MouseEvent<HTMLButtonElement>,
        image: BackgroundImage
    ) => {
        event.stopPropagation();
        setOpen(true);
        setToDelete(image);
    };

    return (
        <div className={"w-full flex justify-center"}>
            <div className={"max-w-4xl"}>
                <div className={"flex justify-between mb-4"}>
                    <Typography variant={"h5"}>Hintergründe</Typography>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={onCreate}
                    >
                        Erstellen
                    </Button>
                </div>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Erstellt am</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {images &&
                                images.map((image: BackgroundImage) => (
                                    <TableRow
                                        className={"cursor-pointer"}
                                        hover
                                        key={image?.imageId}
                                    >
                                        <TableCell component="th" scope="row">
                                            {image?.imageId}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {image?.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {moment(image?.createdAt).format("DD.MM.YYYY - hh:mm")}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={(event) => openDialog(event, image)}
                                            >
                                                <DeleteIcon color={"error"}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <ConfirmDialog
                isOpen={open}
                title={"Bild löschen?"}
                content={"Soll das Bild endgültig gelöscht werden?"}
                onOk={onDelete}
                onCancel={() => setOpen(false)}
            />
            <BackDropFullScreen isOpen={isLoading}/>
        </div>
    );
}

export default BackgroundImagesPage;
