import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AdminsPage from "../../pages/admin/AdminsPage";
import CategoriesPage from "../../pages/category/CategoriesPage";
import CouponsPage from "../../pages/coupon/CouponsPage";
import {makeStyles} from "@material-ui/core/styles";
import SignInPage from "../../pages/auth/SignInPage";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import RedeemOutlinedIcon from "@material-ui/icons/RedeemOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import {useAuth} from "../../stores/useAuth";
import NotFoundPage from "../../pages/notfound/NotFoundPage";
import ProtectedRoute from "./ProtectedRoute";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import AdminCreatePage from "../../pages/admin/AdminCreatePage";
import AdminDetailPage from "../../pages/admin/AdminDetailPage";
import AdminEditPage from "../../pages/admin/AdminEditPage";
import CategoryCreatePage from "../../pages/category/CategoryCreatePage";
import CategoryEditPage from "../../pages/category/CategoryEditPage";
import CategoryDetailPage from "../../pages/category/CategoryDetailPage";
import CouponCreatePage from "../../pages/coupon/CouponCreatePage";
import CouponEditPage from "../../pages/coupon/CouponEditPage";
import CouponDetailPage from "../../pages/coupon/CouponDetailPage";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import EventsPage from "../../pages/event/EventsPage";
import EventCreatePage from "../../pages/event/EventCreatePage";
import EventEditPage from "../../pages/event/EventEditPage";
import EventDetailPage from "../../pages/event/EventDetailPage";
import MenuPage from "../../pages/menu/MenuPage";
import NotificationList from "../../pages/notifications/NotificationList";
import NotificationsEdit from "../../pages/notifications/NotificationsEdit";
import BackgroundImagesPage from "../../pages/background-images/BackgroundImagesPage";
import {LandscapeOutlined} from "@material-ui/icons";
import BackgroundImagesDetailPage from "../../pages/background-images/BackgroundImagesDetailPage";

export type RouteType = {
  routePath: string;
  routeIcon: any;
  routeText: string;
  onClick?: () => void;
};

export const routes: RouteType[] = [
  {
    routePath: "/admins",
    routeIcon: <PeopleOutlinedIcon />,
    routeText: "Administratoren",
  },
  {
    routePath: "/images",
    routeIcon: <LandscapeOutlined />,
    routeText: "Key-Visuals",
  },
  {
    routePath: "/coupons",
    routeIcon: <RedeemOutlinedIcon />,
    routeText: "Coupons",
  },
  // {
  //   routePath: "/menu",
  //   routeIcon: <CategoryOutlinedIcon />,
  //   routeText: "Menu",
  // },
  {
    routePath: "/categories",
    routeIcon: <CategoryOutlinedIcon />,
    routeText: "Push-Kategorien",
  },{
    routePath: "/push-notifications",
    routeIcon: <NotificationsNone />,
    routeText: "Push-Notifications",
  },
  // {
  //   routePath: "/events",
  //   routeIcon: <EventIcon />,
  //   routeText: "Events",
  // },
  {
    routePath: "/auth/signin",
    routeIcon: <PermIdentityOutlinedIcon />,
    routeText: "Anmelden",
  },
  {
    routePath: "/auth/signin",
    routeIcon: <ExitToAppOutlinedIcon />,
    routeText: "Abmelden",
    onClick: useAuth.getState().signOut,
  },
];

const useStyles = makeStyles((theme) => ({
  content: {},
}));

function MainSwitch() {
  const classes = useStyles();
  const { token } = useAuth();

  return (
    <main className={classes.content}>
      <Switch>
        <ProtectedRoute exact path="/admins" component={AdminsPage} />
        <ProtectedRoute path="/admins/create" component={AdminCreatePage} />
        <ProtectedRoute
          path="/admins/:adminId/edit"
          component={AdminEditPage}
        />
        <ProtectedRoute path="/admins/:adminId" component={AdminDetailPage} />

        <ProtectedRoute exact path="/images" component={BackgroundImagesPage} />
        <ProtectedRoute exact path="/images/create" component={BackgroundImagesDetailPage} />

        <ProtectedRoute exact path="/categories" component={CategoriesPage} />
        <ProtectedRoute
          path="/categories/create"
          component={CategoryCreatePage}
        />
        <ProtectedRoute
          path="/categories/:categoryId/edit"
          component={CategoryEditPage}
        />
        <ProtectedRoute
          path="/categories/:categoryId"
          component={CategoryDetailPage}
        />

        <ProtectedRoute exact path="/coupons" component={CouponsPage} />
        <ProtectedRoute path="/coupons/create" component={CouponCreatePage} />
        <ProtectedRoute
          path="/coupons/:couponId/edit"
          component={CouponEditPage}
        />
        <ProtectedRoute
          path="/coupons/:couponId"
          component={CouponDetailPage}
        />

        {/* Background images*/}
        <ProtectedRoute exact path="/images" component={BackgroundImagesPage} />

        {/*Events*/}
        <ProtectedRoute exact path="/events" component={EventsPage} />
        <ProtectedRoute path="/events/create" component={EventCreatePage} />
        <ProtectedRoute
          path="/events/:eventId/edit"
          component={EventEditPage}
        />
        <ProtectedRoute path="/events/:eventId" component={EventDetailPage} />

        {/*Push-Notifications*/}
        <ProtectedRoute exact path="/push-notifications" component={NotificationList} />
        <ProtectedRoute exact path="/push-notifications/create" component={NotificationsEdit} />
        <ProtectedRoute exact path="/push-notifications/:id/edit" component={NotificationsEdit} />

        <ProtectedRoute exact path="/menu" component={MenuPage} />

        <Route exact path="/auth/signin" component={SignInPage} />
        <Route exact path="/">
          {token ? <Redirect to="/admins" /> : <SignInPage />}
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
    </main>
  );
}

export default MainSwitch;
