import React, {ChangeEvent, Component, FormEvent, useState} from 'react';

export const ImageUpload = ({onImage}: { onImage: (imageAsBase64: string) => void }) => {

    return (
        <div>
            <form onChange={e => {
                // @ts-ignore
                const file = e.target.files[0];
                console.log("File to upload: ", file);

                if (file) {
                    const reader = new FileReader();
                    reader.onload = (readerEvent) => {
                        const binaryString = readerEvent?.target?.result;
                        // @ts-ignore
                        onImage(btoa(binaryString));
                    }
                    reader.readAsBinaryString(file);
                }
            }}
                  onSubmit={(e) => {
                      e.preventDefault();
                  }}>
                <input
                    type={"file"}
                    name={"image"}
                    id={"file"}
                    accept={".jpeg, .jpg"}/>
            </form>
        </div>
    )
}
