import {useQuery} from "react-query";
import {BackgroundImage, BackgroundImageService} from "../services/BackgroundImageService";

export const useBackgroundImagesQuery = () => {
    return useQuery<BackgroundImage[], Error>(
        ["backgroundImages"],
        async () => {
            return await BackgroundImageService.GetAll()
        }, {
            cacheTime: 0
        }
    );
};
